import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Logo from "./images/image_logo_black"

const Header = ({ siteTitle }) => (
  <nav className="navbar navbar-light bg-light">
    <div
      className="container-fluid"
      style={{ paddingTop: "5px", paddingBottom: "5px" }}
    >
      <Link to="/">
        <div style={{ height: "30px", width: "200px" }}>
          <Logo />
        </div>
      </Link>
    </div>
  </nav>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
