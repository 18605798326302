/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import "popper.js"
import "bootstrap/dist/css/bootstrap.min.css"
import "@fortawesome/fontawesome-free/css/all.css"

import "./custom.css"
import "./btn-circle.css"
import Header from "./header"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div className="container">
        <main className="py-3">{children}</main>
        <footer>
          <div className="d-flex justify-content-center pb-2">
            <a
              className="btn btn-info btn-circle btn-circle-lg m-1"
              href="mailto:info@tastesoft.net"
              role="button"
            >
              <i className="far fa-envelope"></i>
            </a>
          </div>
          <div className="d-flex justify-content-center">
            <Link to="/privacy-policy">Privacy policy</Link>
          </div>
          <div className="d-flex justify-content-center">
            © {new Date().getFullYear()} Tastesoft
          </div>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
